import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  productSupliers: [],
  languages:'es',
  footerCategories:[]
}

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setProdectSupliers: (state, action) => {
      state.productSupliers = action.payload
    },
    setLanguages: (state, action) => {
      state.languages = action.payload;
    },
    setFooterCategories: (state, action) => {
      state.footerCategories = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { setProdectSupliers,setLanguages ,setFooterCategories} = productSlice.actions

export default productSlice.reducer