import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import { imageBaseUrl } from "../../services/configure";
import 'swiper/swiper-bundle.css';

const PartnerSlider = ({ suppliers, onSupplierSelect }) => {
  
  return (
    <>
      <div className="solution-slider slider-container partner-slide partner-logo-slider">
        <div className="slider-custom-container">
          <Swiper 
          modules={[Navigation, Autoplay]}
          
          slidesPerView={10} spaceBetween={30} nextButton= '.next-slide'
          prevButton= '.prev-slide'
          navigation={{
            nextEl: '.next-slide',
            prevEl: '.prev-slide',
          }}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false, 
          }}
          
          breakpoints={{
            1400: {
              slidesPerView: 10,
            },
            1200: {
              slidesPerView: 5,
            },
            768: {
              slidesPerView: 5,
            },
            576: {
              slidesPerView: 3,
            },
            360: {
              slidesPerView: 2,
            },
          }}
          className="mySwiper">
            {suppliers.map(
              (supplier, index) =>
                supplier.logo && (
                  <React.Fragment key={index}>
                    <SwiperSlide>
                      <div className="image-overlay">
                        <img
                          src={imageBaseUrl + supplier.logo}
                          alt={supplier.name}
                          onClick={() => onSupplierSelect(supplier)}
                          className="w-100"
                        />
                      </div>
                    </SwiperSlide>
                    {supplier.associated_suppliers &&
                      supplier.associated_suppliers.map((subSupplier) => (
                        <SwiperSlide key={subSupplier.id}>
                          <div className="image-overlay">
                            <img
                              src={imageBaseUrl + subSupplier.logo}
                              alt={subSupplier.name}
                              className="w-100"
                              onClick={() => onSupplierSelect(subSupplier)}
                            />
                          </div>
                        </SwiperSlide>
                      ))}
                  </React.Fragment>
                )
            )}
          </Swiper> 
        </div>
      </div>
    </>
  );
};

export default PartnerSlider;
