import axios from "axios";
import { baseUrl } from "./configure";

export async function GetSuppliers() {
  try {
    const reps = await axios.get(`${baseUrl}suppliers`);
    return reps.data;
  } catch (error) {
  }
}
export async function ProductList() {
  try {
    const reps = await axios.get(`${baseUrl}products`);
    return reps.data;
  } catch (error) {
  }
}
// to get categories details
export async function FilterProductSupplier(id) {
  const supplierId = Number(id);
  try {
    const reps = await axios.get(`${baseUrl}suppliers/${id}`);
    return reps.data;
  } catch (error) {
  }
}
// to get categories details
export async function FilterProductPage(pages_data) {
  try {
    const reps = await axios.get(`${baseUrl}products/filter`,{
      params: pages_data
    });
    return reps.data;
  } catch (error) {
  }
}
// to get categories details

export async function GetCategories() {
  try {
    const reps = await axios.get(`${baseUrl}categories`);
    return reps.data;
  } catch (error) {
  }
}

// to get Leadership Team

export async function GetLeadership() {
  try {
    const reps = await axios.get(`${baseUrl}teams/leadership-team`);
    return reps.data;
  } catch (error) {
  }
}

// to get Expert Team

export async function GetExpertTeam() {
  try {
    const reps = await axios.get(`${baseUrl}teams/expert-team`);
    return reps.data;
  } catch (error) {
  }
}

// to get Event Listing

export async function GetEvent() {
  try {
    const reps = await axios.get(`${baseUrl}event`);
    return reps.data;
  } catch (error) { 
  }
}

// to get Sales Team

export async function GetSales() {
  try {
    const reps = await axios.get(`${baseUrl}teams/sales-team`);
    return reps.data;
  } catch (error) {
  }
}

// to get event details
export async function EventDetails(id) {
  try {
    const reps = await axios.get(`${baseUrl}event/${id}`);
    return reps.data;
  } catch (error) {
  }
}


// to get product details by id
export async function ProductDetailpage(id) {
  try {
    const reps = await axios.get(`${baseUrl}product/${id}`);
    return reps.data;
  } catch (error) {
  }
}


// to get region list

export async function Regions() {
  try {
    const reps = await axios.get(`${baseUrl}regions`);
    return reps.data;
  } catch (error) { 
  }
}

// to post conatct form
export async function ContactUS_API(userInfo) {
  try {
    const response = await axios.post(`${baseUrl}contact-us`,userInfo, {
      headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });
   return response.data
  } catch (error) {
  }
}
// to post conatct form
export async function RequestQuote_API(quoteInfo) {
  try {
    const response = await axios.post(`${baseUrl}request-quote`,quoteInfo, {
      headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });
   return response.data
  } catch (error) {
  }
}