import React from 'react'
import Layout from '../pages/layout/Layout'
import { ThisAhern } from '../pages/about/ThisAhern'
import Ahernbio from '../pages/solutions/Ahernbio'
import Seeds from '../pages/products/Seeds'
import Event from '../pages/news/Event'
import { Routes, Route, Navigate } from "react-router-dom";
import { Contact } from '../pages/contact/Contact';
import Home from '../pages/home/Home'
import Ahernseeds from '../pages/solutions/Ahernseeds'
import Ahernfin from '../pages/solutions/Ahernfin'
import ProductDetail from '../pages/products/ProductDetail'
import EventDetail from '../pages/news/EventDetail'
import PrivacyPolicy from '../pages/layout/PrivacyPolicy'
import CookiePolicy from '../pages/layout/Cookies'
const AppRouters = () => {

  return (
    <>      
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about-us" element={<ThisAhern />} /> 
          <Route path="contact-us" element={<Contact />} />
          <Route path="ahernbio" element={<Ahernbio />} />
          <Route path="ahernseeds" element={<Ahernseeds />} />
          <Route path="ahernfin" element={<Ahernfin />} />
          <Route path="products" element={<Seeds />} />
          <Route path="events" element={<Event />}/>
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="cookie-policy" element={<CookiePolicy />} />
          <Route path="product-detail/:id" element={<ProductDetail />} />
          <Route path="EventDetail/:id" element={<EventDetail />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Routes>
    </>
  )
}

export default AppRouters;