import React, { useEffect, useState } from "react";
import LeadershipSlider from "./LeadershipSlider";
import boomingGameData from "../../assets/videos/AHER_MASTER_SUB_EN_WEB-BAJA.mp4?autoplay=1";
import boomingGameDataes from "../../assets/videos/AHER_MASTER_SUB_ESP_WEB_BAJA.mp4?autoplay=1";
import { useTranslation } from "react-i18next";

export const ThisAhern = () => {
  const { t, i18n } = useTranslation("global"); // Use the "global" namespace
  const [videoSrc, setVideoSrc] = useState(boomingGameDataes);

  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);

  
  useEffect(() => {
    setVideoSrc(i18n.language === "en" ? boomingGameDataes : boomingGameData);
  }, [i18n.language]);
  return (
    <>
      <div className="banner-main">
        <div className="banner-image-thisisahern"></div>
      </div>
      <div className="about-us-mission">
        <div className="this-ahern-header text-center">
          <h2 className="uppercase">{t("Our Mission")}</h2>
          <p>
            {t("Proporcionar")}
          </p>
        </div>
        <div className="outer-about-us custom-container">
          <div className="about-video">
            <video
              key={i18n.language}
              autoPlay
              loop
              muted
              controls
              style={{ width: "100%", border: "1px solid gray" }}
            >
              <source src={videoSrc} />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
      <div className="our-values-main">
        <div className="custom-container">
          <div className="our-mission-header">
            <h3>{t("This is Ahern")}</h3>
          </div>
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12">
              <div className="vlaue-main">
                <p>
                  {t("At")} <span>Ahern,</span> {t("p1")}
                </p>
                <p>
                  {t("p2")}
                </p>
                <p>
                  {t("p3")}
                </p>
                <p>
                {t("At")} <span>Ahern,</span> {t("p4")}
                </p>
                <p>
                  <span>{t("Empower Your Growth")} </span> {t("represents")} {""}
                  <span>Ahern’s</span> {t("p5")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="our-solution meet-our-team">
        <div className="custom-container solution-header text-center ">
          <h3>{t("Meet our leadership team")}</h3>
        </div>
        <LeadershipSlider />
      </div>
    </>
  );
};
export default ThisAhern;
