import React, { useEffect, useState } from 'react';
import logoFooter from "../../assets/images/LOGOAHERN2.png";
import { FaceBookIcon, InstagramIcon, LinkedinIcon, YouTubeIcon, EnvelopeIcon, LocationIcon, TikTok } from "../../assets/svg";
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { GetCategories } from '../../services/Api';
import { setFooterCategories } from '../../redux/features/productSlice';

const Footer = () => {
  const [filter, setFilter] = useState({ category: null, supplier: null });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation("global");
  const { footerCategories } = useSelector((state) => state?.product);

  

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await GetCategories();
        dispatch(setFooterCategories(response?.productCategories));
      } catch (error) {
        console.error(error);
      }
    };

    fetchCategories();
  }, [dispatch]);

  const handleSuppliers = (supplier, event) => {
    event.preventDefault(); 
    const params = new URLSearchParams();
    params.set("supplier", supplier);
    navigate(`/products?${params.toString()}`);
};
  const handleCategories = (category) => {
    const params = new URLSearchParams();
    params.set("category", category);
    navigate(`/products?${params.toString()}`);
  };



  return (
    <div className="footer">
      <div className="custom-container footer-main">
        <div className="row">
          <div className="col-xxl-3 col-xl-12 col-lg-12">
            <div className="footer-col">
              <div className="navbar-brand">
                <img
                  src={i18n.language === "en" ? logoFooter : logoFooter}
                  alt="logo"
                />
              </div>
              <div className="footer-col-bottom">
                <h4>{t("Follow us")}</h4>
                <div className="icon-social">
                  <a
                    href="https://www.facebook.com/Ahernseedsmx?locale=es_LA"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social-bg"
                  >
                    <FaceBookIcon color="#FFFFFF" />
                  </a>
                  <a
                    href="https://www.instagram.com/ahernseeds/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social-bg"
                  >
                    <InstagramIcon color="#FFFFFF" />
                  </a>
                  <a
                    href="https://www.youtube.com/@AhernSeeds/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social-bg"
                  >
                    <YouTubeIcon color="#FFFFFF" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/ahernagribusiness/about/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social-bg"
                  >
                    <LinkedinIcon color="#FFFFFF" />
                  </a>
                  <a
                    href="https://www.tiktok.com/@ahernseeds"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social-bg"
                  >
                    <TikTok color="#FFFFFF" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
            <div className="footer-col">
              <h4>{t("Products")}</h4>
              <ul className="product-footer">
                {footerCategories?.map((item) => {
                  const isSelected = filter.category === item.id;
                  return (
                    <li
                      value={item.id}
                      key={item.id}
                      className={isSelected ? "selected" : ""}
                    >
                      <button
                          onClick={() => handleCategories(item.id)} >
                          {i18n.language === "en" ? item.name_en : item.name_es}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-3 col-6">
            <div className="footer-col">
              <h4>{t("Our Solutions")}</h4>
              <ul className="">
                <li>
                  <Link className="dropdown-item nav-link" to="/ahernseeds">
                    {t("ahern Seeds")}
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item nav-link" to="/ahernBio">
                    {t("Ahern Bio Solutions")}
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item nav-link" to="">
                    {t("Ahern Financial")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-6">
    <div className="footer-col">
        <h4>{t("Suppliers")}</h4>
        <ul className="supplier-listing-footer">
            <li>
                <button onClick={(e) => handleSuppliers(1, e)}>
                    {t("Bayer")}
                </button>
            </li>
            <li>
                <button onClick={(e) => handleSuppliers(4, e)}>
                    {t("HM Clause")}
                </button>
            </li>
            <li>
                <button onClick={(e) => handleSuppliers(6, e)}>
                    {t("Syngenta")}
                </button>
            </li>
            <li>
                <button onClick={(e) => handleSuppliers(5, e)}>
                    {t("Enza Zaden")}
                </button>
            </li>
            <li>
                <button onClick={(e) => handleSuppliers(7, e)} >
                    {t("Hazera")}
                </button>
            </li>
        </ul>
    </div>
</div>
          <div className="col-xxl-2 col-xl-3 col-lg-12">
            <div className="footer-col">
              <h4>{t("Contact Us")}</h4>
              <ul className="footer-last-col">
                <li>
                  <EnvelopeIcon color="#000" />
                  <a className="email-link" href="mailto:marketingahern@ahernseeds.com">
                    {t("marketingahern@ahernseeds.com")}
                  </a>
                </li>
                <li>
                  <LocationIcon color="#000" />
                  <p>{t("adress")}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom footer-background">
        <div className="footer-layer">
          <p>{t("Copyright")}</p>
          <ul>
          <Link to="/privacy-policy">
          <li>{t("Privacy Policy")}</li>
            </Link>
            <Link to="/cookie-policy">
              {" "}
              <li>{t("Cookie Policy")}</li>
            </Link>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
