import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { imageBaseUrl } from '../../services/configure';
const ProductSlider = (data) => {

    const productImages = data?.data?.[0].product_gallery || []; 
    const settings = {
      dots: false,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 1000,
      pauseOnHover: true,
    };
    return (
      <div className="product-slider">
        <div className="slider-custom-container">
          <Slider {...settings}>
            {productImages.length > 0 ? (
              productImages.map((productImage, index) => (
                <div className="image-overlay" key={index}>
                  <img
                    src={`${imageBaseUrl + productImage.url}`}
                    alt="Product Images"
                    className="w-full"
                  />
                </div>
              ))
            ) : (
              <div>No product banner available</div>
            )}
          </Slider>
        </div>
      </div>
    );
}

export default ProductSlider