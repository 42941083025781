import React, { useEffect, useState } from "react";
import { ArrowIcon } from "../../assets/svg";
import { EventDetails } from "../../services/Api";
import { imageBaseUrl } from "../../services/configure";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const EventDetail = () => {
  const [eventDetails, setEventDetails] = useState();
  const [contenteventDetails, setContentEventDetails] = useState([]);
  const { id } = useParams();
  const { t, i18n } = useTranslation("global");
  const handleEventDetails = async (id) => {
    try {
      const response = await EventDetails(id);
      setEventDetails(response?.event);
      setContentEventDetails(response?.event?.details);
    } catch (error) {}
  };
  useEffect(() => {
    handleEventDetails(id);
    window.scrollTo(0, 0);
  }, [id]);

  return (
    <>
      <div className="banner-main">
        <div className="banner-image-event-detail"></div>
      </div>
      <div className="event-detail-main">
        <div className="custom-container">
          <div className="event-detailid">
            <div className="back-btn event-listing">
              <Link
                to={{
                  pathname: `/events/`,
                }}
              >
                <ArrowIcon /> {t("Back To Event Listing")}
              </Link>
            </div>

            <div className="event-detail-header text-center">
              <h2>
                {i18n.language === "en"
                  ? eventDetails?.title_en
                  : eventDetails?.title_es}
              </h2>
            </div>

            {contenteventDetails && contenteventDetails.length > 0 ? (
              contenteventDetails.map((item, index) => {
                const mediaCount = item?.media?.length || 0;
                const isEven = index % 2 === 0;

                return (
                  <div key={index} className="main-event-row">
                    {mediaCount > 0 ? (
                      <div className="row">
                        {mediaCount === 1 ? (
                          <>
                            {/* For even index: Content on left, Image on right */}
                            {/* For odd index: Image on left, Content on right */}
                            <div className={isEven ? "col-md-6" : "col-md-6"}>
                              {isEven ? (
                                <div className="event-detail-thumbnails">
                                  <div className="event-image">
                                    {item?.media?.map((image, imgIndex) => (
                                      <img
                                        alt="event-image"
                                        width="100%"
                                        height="100%"
                                        src={`${imageBaseUrl + image.file}`}
                                        key={imgIndex}
                                      />
                                    ))}
                                  </div>
                                </div>
                              ) : (
                                <div className="event-detail-left">
                                  <div className="event-content">
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          i18n.language === "en"
                                            ? item.content_en
                                            : item.content_es,
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>

                            <div className={isEven ? "col-md-6" : "col-md-6"}>
                              {isEven ? (
                                <div className="event-detail-left">
                                  <div className="event-content">
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          i18n.language === "en"
                                            ? item.content_en
                                            : item.content_es,
                                      }}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div className="event-detail-thumbnails">
                                  <div className="event-image">
                                    {item?.media?.map((image, imgIndex) => (
                                      <img
                                        width="100%"
                                        height="100%"
                                        src={`${imageBaseUrl + image.file}`}
                                        key={imgIndex}
                                        alt="event-image"
                                      />
                                    ))}
                                  </div>
                                </div>
                              )}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="col-md-12">
                              <div className="event-detail-thumbnails">
                                <div className="event-image">
                                  {item?.media?.map((image, imgIndex) => (
                                    <img
                                      width="100%"
                                      height="100%"
                                      src={`${imageBaseUrl + image.file}`}
                                      key={imgIndex}
                                      alt="event-image"
                                    />
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="event-detail-left">
                                  <div className="event-content">
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          i18n.language === "en"
                                            ? item.content_en
                                            : item.content_es,
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    ) : (
                      <div className="row">
                        <div className="col-md-12">
                          <div className="event-detail-left">
                            <div className="event-content">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html:
                                    i18n.language === "en"
                                      ? item.content_en
                                      : item.content_es,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })
            ) : (
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-8 col-lg-6">
                    <div
                      className="no-details-message text-center alert alert-warning"
                      role="alert"
                    >
                      <h4 className="no-details-title">
                        Event Information Not Found
                      </h4>
                      <p className="no-details-description">
                        We apologize, but there are no details available for
                        this event at this time. Please revisit later or contact
                        our support team for further assistance. We appreciate
                        your patience!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default EventDetail;
