import React, { useEffect, useState } from "react";
import mapImage from "../../assets/images/MAP_AHERN.png";
import { useTranslation } from 'react-i18next';



const Ahernseeds = () => {
   const { t, i18n } = useTranslation("global"); 
    useEffect(() => {
       window.scrollTo(0, 0); // Scroll to the top of the page
     }, []);
  return (
    <>
      <div className="banner-main">
        <div className="banner-image-ahernseeds"></div>
      </div>
      <div className="ahern-seeds-main">
      <div className="custom-container">
        <div className="product-header width-75 mx-auto">
          <h3 className="text-center pt-5">
            {t("we provide")}
          </h3>
          <div className="content-seeds">
            <p>
              {t("seeds1")} </p>
              <p>{t("seeds2")} </p>
              <p>{t("seeds3")}</p>
            {/* <p>{t("seeds4")}</p> */}
          </div>
          <div className="map-image">
          <h3 className="text-center pt-5">
          {t("seeds5")}
          </h3>
          <img src={mapImage} alt="map" />
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default Ahernseeds;
