import React, { useEffect, useState } from "react";
import SlickSlider from "./SlickSlider";
import boomingGameData from "../../assets/videos/AHER_MASTER_SUB_EN_WEB-BAJA.mp4?autoplay=1";
import boomingGameDataes from "../../assets/videos/AHER_MASTER_SUB_ESP_WEB_BAJA.mp4?autoplay=1";
import Solutionslider from "./Solutionslider";
import { ContactUS_API, Regions } from "../../services/Api";
import {  useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

const Home = () => {
  const [regions, setRegions] = useState({});
  const [selectedState, setSelectedState] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); 
  const [loading, setLoading] = useState(false); 
  const [userDetail, setUserDetail] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  });
  const [isToastVisible, setIsToastVisible] = useState(false);
  const {languages} = useSelector((state)=>state.product)
  const { t, i18n } = useTranslation("global");

const [selectedUser, setSelectedUser] = useState("");

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedUser(value);
  };
  const fetchRegions = async () => {
    try {
      const response = await Regions();
      if (response?.success) {
        setRegions(response.regions);
      }
    } catch (error) {
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    fetchRegions();
  }, []);

  const handleStateSelect = (state) => {
    setSelectedState(state);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userDetail.message.length > 500) {
      setIsToastVisible(true);
      toast.error(t("error.Messagelimit"), {
        onClose: () => setIsToastVisible(false),
      });
      setLoading(false);
      return false;
    }
    if (
      !userDetail.name ||
      !selectedState ||
      !userDetail.email ||
      !userDetail.message
    ) {
      if (!isToastVisible) {
        setIsToastVisible(true);

        toast.error(t("error.fillMandatoryDetails"), {
          onClose: () => setIsToastVisible(false),
        });
      }
      return;
    }
    setLoading(true);


    const userInfo = {
      name: userDetail.name,
      region: selectedState,
      email: userDetail.email,
      role: selectedUser,
      phone: userDetail.phoneNumber,
      message: userDetail.message,
      language:languages
      
    };
    try {
      await ContactUS_API(userInfo);
      setUserDetail({
        name: "",
        email: "",
        role: "",
        phoneNumber: "",
        message: "",
      });
      setSelectedState("");
      setLoading(false);
      toast.success(t('success.suceccfull submit'));
    } catch (error) {
      toast.error("An error occurred while submitting the form.");
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetail((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [videoSrc, setVideoSrc] = useState(boomingGameDataes);
  useEffect(() => {
    setVideoSrc(i18n.language === 'en' ? boomingGameDataes : boomingGameData);
  }, [i18n.language]);
  return (
    <>
    {loading && (
      <div className="loader-overlay">
        <div className="loader"></div>
      </div>
    )}

      <SlickSlider />
      <div className="this-ahern-main">
        <div className="this-ahern-header text-center">
          <h3>{t("THIS IS AHERN")}</h3>
          <p>
          {t("preferred strategic")}
          </p>
        </div>
        <div className="outer-about-us custom-container">
          <div className="about-video">
            <video
            key={i18n.language}
              autoPlay
              loop
              muted
              controls
              style={{ width: "100%", border: "1px solid gray" }} 
            >
              <source src={videoSrc} />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
      <div>
      </div>
      <div className="our-solution">
        <div className="custom-container solution-header text-center">
          <h3>{t("WHAT WE OFFER")}</h3>
        </div>
        <Solutionslider />
      </div>
      <div className="outer-contact-us">
        <div className="custom-container contact-us-main">
          <div className="row">
            <div className="col-lg-8 col-md-12 col-sm-12">
              <div className="contact-form">
                <div className="contact-left-card">
                  <h3>{t("Contact Us")}</h3>
                  <form
                    className="row contact-form-main"
                    onSubmit={handleSubmit}
                  >
                    <div className="col-md-12 mb-3">
                      <div className="position-relative">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder={`${t("Name")} *`}
                        name="name"
                        value={userDetail.name}
                        onChange={handleChange}
                      />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <div className="position-relative">
                        <select
                        className="form-select"
                          value={selectedState || ""}
                          onChange={(e) => handleStateSelect(e.target.value)}
                          aria-label="Select State"
                        >
                          <option value="">{`${t("Region")} *`}</option>
                          {Object.keys(regions).map((region) => (
                            <optgroup label={region} key={region}>
                              {regions[region].map((state) => (
                                <option value={state} key={state}>
                                  {state}
                                </option>
                              ))}
                            </optgroup>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                    <div className="position-relative">
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder={`${t("Email")} *`}
                        name="email"
                        value={userDetail.email}
                        onChange={handleChange}
                      />
                    </div>
                    </div>
                    <div className="col-md-12 mb-3">
                    <div className="position-relative">
                      <input
                        type="number"
                        className="form-control"
                        id="phone"
                        placeholder={`${t("Phone")}`}
                        name="phoneNumber"
                        value={userDetail.phoneNumber}
                        onChange={handleChange}
                      />
                    </div>
                    </div>
                    <div className="col-md-12 mb-3">
                    <select
                      id="userSelect"
                      className="form-select"
                      aria-label="Default select example"
                      name="Select USer"
                      value={selectedUser}
                      onChange={handleSelectChange}
                    >
                      <option value="" disabled>
                        {`${t("role")}`}
                      </option>
                      <option value="Customer">{t("Customer")}</option>
                      <option value="Supplier">{t("Supplier")}</option>
                    </select>
                  </div>
                    <div className="col-md-12 mb-3">
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        placeholder={`${t("Message")} *`}
                        rows="3"
                        name="message"
                        value={userDetail.message}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <div className="col-md-12 mb-3">
                      <div className="submit-button-main">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? t("Submitting...") : t("Submit")}
                        </button>
                      </div>
                    </div>
                    <ToastContainer />
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-4"></div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Home;
