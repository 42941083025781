// src/CookiePolicy.js
import React, {  useEffect } from 'react';
import { useTranslation } from "react-i18next";

const CookiePolicy = () => {
    const { t } = useTranslation("global");

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      

        return (
            <div className="cookies-policy-wrapper container mt-5">
                <h1 className="text-center">{t('cookiepolicy.heading_1')}</h1>
                <p dangerouslySetInnerHTML={{ __html: t('cookiepolicy.paragraph_1') }} />
                
                <h4>{t('cookiepolicy.heading_2')}</h4>
                <p>{t('cookiepolicy.paragraph_2')}</p>
                
                <h4>{t('cookiepolicy.heading_3')}</h4>
                <p>{t('cookiepolicy.paragraph_3')}</p>
                <ul>
                    <li>
                        <p dangerouslySetInnerHTML={{ __html: t('cookiepolicy.list_1') }} />
                    </li>
                    <li>
                        <p dangerouslySetInnerHTML={{ __html: t('cookiepolicy.list_2') }} />
                    </li>
                    <li>
                        <p dangerouslySetInnerHTML={{ __html: t('cookiepolicy.list_3') }} />
                    </li>
                    <li>
                        <p dangerouslySetInnerHTML={{ __html: t('cookiepolicy.list_4') }} />
                    </li>
                </ul>

                <h4>{t('cookiepolicy.heading_4')}</h4>
                <p>{t('cookiepolicy.paragraph_4')}</p>
                
                <h4>{t('cookiepolicy.heading_5')}</h4>
                <p>{t('cookiepolicy.paragraph_5')}</p>
                <p>{t('cookiepolicy.paragraph_6')}</p>
                <ul>
                    <li>
                        <p dangerouslySetInnerHTML={{ __html: t('cookiepolicy.list_5') }} />
                    </li>
                    <li>
                        <p dangerouslySetInnerHTML={{ __html: t('cookiepolicy.list_6') }} />
                    </li>
                    <li>
                        <p dangerouslySetInnerHTML={{ __html: t('cookiepolicy.list_7') }} />
                    </li>
                    <li>
                        <p dangerouslySetInnerHTML={{ __html: t('cookiepolicy.list_8') }} />
                    </li>
                </ul>

                <h4>{t('cookiepolicy.heading_6')}</h4>
                <p>{t('cookiepolicy.paragraph_7')}</p>
                <p dangerouslySetInnerHTML={{ __html: t('cookiepolicy.paragraph_8') }} />
                <p>{t('cookiepolicy.paragraph_9')}</p>
                <strong><a href={`mailto:${t('marketingahern@ahernseeds.com')}`}>{t('marketingahern@ahernseeds.com')}</a></strong>
            </div>
        );
    
}

export default CookiePolicy;