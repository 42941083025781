// src/PrivacyPolicy.js
import React, {  useEffect } from 'react';
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
    const { t } = useTranslation("global");

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

        return (
            <div className="privacy-policy-wrapper container mt-5">
                <h1 className="text-center">{t('privacypolicy.heading_1')}</h1>
                <p dangerouslySetInnerHTML={{ __html: t('privacypolicy.update_date') }} />
                <p dangerouslySetInnerHTML={{ __html: t('privacypolicy.paragraph_1') }} />
                <h4>{t('privacypolicy.heading_2')}</h4>
                <p>{t('privacypolicy.paragraph_2')}</p>
                <ul>
                    <li>
                    <p dangerouslySetInnerHTML={{ __html: t('privacypolicy.list_1') }} />
                    </li>
                    <li>
                    <p dangerouslySetInnerHTML={{ __html: t('privacypolicy.list_2') }} />
                    </li>
                </ul>
                <h4>{t('privacypolicy.heading_3')}</h4>
                <p>{t('privacypolicy.paragraph_3')}</p>
                <ul>
                    <li><p>{t('privacypolicy.list_3') }</p></li>
                    <li><p>{t('privacypolicy.list_4') }</p></li>
                    <li><p>{t('privacypolicy.list_5') }</p></li>
                    <li><p>{t('privacypolicy.list_6') }</p></li>
                    <li><p>{t('privacypolicy.list_7') }</p></li>
                </ul>

                <h4>{t('privacypolicy.heading_4')}</h4>
                <p>{t('privacypolicy.paragraph_4') }</p>
                <p>{t('privacypolicy.paragraph_5') }</p>

                <h4>{t('privacypolicy.heading_5')}</h4>
                <p>{t('privacypolicy.paragraph_6') }</p>

                <h4>{t('privacypolicy.heading_6')}</h4>
                <p>{t('privacypolicy.paragraph_7') }</p>

                <h4>{t('privacypolicy.heading_7')}</h4>
                <p>{t('privacypolicy.paragraph_8') }</p>

                <h4>{t('privacypolicy.heading_8')}</h4>
                <p>{t('privacypolicy.paragraph_9') }</p>

                <h4>{t('privacypolicy.heading_9')}</h4>
                <p>{t('privacypolicy.paragraph_10') }</p>

                <h4>{t('privacypolicy.heading_10')}</h4>
                <p>{t('privacypolicy.paragraph_11') }</p>

                <ul>
                    <li><p>{t('privacypolicy.list_8') } <strong><a href={`mailto:${t('marketingahern@ahernseeds.com')}`}>{t('marketingahern@ahernseeds.com')}</a></strong></p></li>
                    <li><p>{t('privacypolicy.list_9') }</p></li>
                </ul>
            </div>
     
   ) }

export default PrivacyPolicy;