import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { GetLeadership } from "../../services/Api";
import { imageBaseUrl } from "../../services/configure";
import { useTranslation } from "react-i18next";

const LeadershipSlider = () => {

  const [leadership, setLeadership] = useState([]);
  const { i18n } = useTranslation("global");
  const handleLeadership = async () => {
    try {
      const response = await GetLeadership();
      setLeadership(response.teams)
    } catch (error) {
    }
  };
  useEffect(() => {
    handleLeadership();
  }, []);

  var settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: 0,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2
        }
      }
    ]
  };
  return (
    <>
      <div className="custom-container solution-slider slider-container leadership-slider-container">
        <div className="slider-custom-container">
          <Slider {...settings}>
            {leadership?.map((item, index) => (
              <div className="image-box" key={index}>
                <div className="image-overlay seeds-green mx-4">
                  <img src={`${imageBaseUrl + item?.profile}`} alt="Background" className="w-100" />
                  <div className="head-sol">
                    <div className="heding-content">
                      <h5>{item?.name}</h5>
                      <p className="position">
                        {/* {item?.position} */}
                        {i18n.language === 'en' ? item?.position_en : item?.position_es}
                      </p>
                      <p>{item?.email}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </>
  )
}

export default LeadershipSlider