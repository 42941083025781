
import React, { useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slide1 from "../../assets/images/IMAGE1.jpg";
import slide2 from "../../assets/images/HOME_IMAGE_12.jpg";
import slide3 from "../../assets/images/HOME_IMAGE_10.jpg";
import slide4 from "../../assets/images/HOME_IMAGE_9.jpg";
import logoImageWhite from '../../assets/images/Logo-slider.png';
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const SlickSlider = () => {

  const { t } = useTranslation("global"); 
  
  var settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true
  };
  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);

  return (
    <>
      <div className="slider-custom-container">
        <Slider {...settings}>
          <div>
            <div className="image-overlay">
              <img src={slide1} alt="Background" className="w-100" />
              <div className="overlay-top"></div>
            </div>
          </div>
          <div className="image-overlay">
            <img src={slide2} alt="Background" className="w-100" />
            <div className="overlay-top"></div>
          </div>
          <div className="image-overlay">
            <img src={slide3} alt="Background" className="w-100" />
            <div className="overlay-top"></div>
          </div>
          <div className="image-overlay">
            <img src={slide4} alt="Background" className="w-100" />
            <div className="overlay-top"></div>
          </div>
        </Slider>
        <div className="custom-container">
          <div className="login-image-content betNow-btn">
            <div className="image-heding-content">
              <h5 className="text-light">{t("Welcome To")}</h5>
              <div className="navbar-brand">
                <img src={logoImageWhite} alt="logo" />
              </div>
              <p className="text">{t("We connect")}</p>
            </div>
            <Link className="btn bet-now-login" to="/about-us">{t("know more")}</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default SlickSlider;
