import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 0,
  salesTeam:[]
}

export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    setSalestTeam: (state, action) => {
      state.salesTeam = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { increment, decrement, setSalestTeam } = counterSlice.actions

export default counterSlice.reducer