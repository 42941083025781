import React, { useEffect, useState } from "react";
import { GetEvent } from "../../services/Api";
import { imageBaseUrl } from "../../services/configure";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Event = () => {
  const [event, setEvent] = useState([]);
  const { t, i18n } = useTranslation("global");
  const isUpcoming = new Date(event.event_date) > new Date();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleEvent = async () => {
    try {
      const response = await GetEvent();
      setEvent(response.events)
    } catch (error) {
    }
  };

  useEffect(() => {
    handleEvent();
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <>
      <div className="banner-main">
        <div className="banner-image-news">

        </div>
      </div>
      <div className="mews-main">
        <div className="custom-container">
          <div className="product-header mt-5 mb-4">
            {/* <h5>{t("Our Events & Blogs")}</h5> */}
            <h3>{t("Connecting with farmers")}</h3>
          </div>
          <div className="row">
            {event?.map((item, index) => {
              const isUpcoming = new Date(item.event_date) > new Date();
              return (
                <div className="col-xl-4 col-lg-12 p-5" key={index}>
                  {
                    isUpcoming ? (
                      <div className="mews-card">
                        <div className="news-image">
                          <img width="100%" height="100%" alt="image" src={`${imageBaseUrl + item?.thumbnail}`} />
                        </div>
                        <div className="news-card">
                          <div className="min-height-box">
                            <h4> {i18n.language === 'en' ? item.title_en : item.title_es}</h4>
                            <p dangerouslySetInnerHTML={{ __html: (i18n.language === 'en' ? item.short_description_en : item.short_description_es) }} />
                          </div>
                          <button 
                                className="btn upcoming" 
                                onMouseEnter={handleMouseEnter} 
                                onMouseLeave={handleMouseLeave}
                            >
                                {isHovered ? (
                                    <p>Date - {item.event_date}</p>
                                ) : (
                                    <p className="upcoming-text">{t("Upcoming")}</p>
                                )}
                            </button>
                        </div>
                      </div>
                    ) :
                  (
                    <Link to={`/EventDetail/${item.id}`}>
                    <div className="mews-card">
                      <div className="news-image">
                        <img width="100%" height="100%" alt="image" src={`${imageBaseUrl + item?.thumbnail}`} />
                      </div>
                      <div className="news-card">
                        <div className="min-height-box">
                          <h4> {i18n.language === 'en' ? item.title_en : item.title_es}</h4>
                          <p dangerouslySetInnerHTML={{ __html: (i18n.language === 'en' ? item.short_description_en : item.short_description_es) }} />
                        </div>
                        <button className="btn bet-now-login">
                          {t("Read More")}
                        </button>
                      </div>
                    </div>
                  </Link>
                  )
                  }
                 
                </div>
            )})}
          </div>
        </div>
      </div>
    </>
  )
}

export default Event