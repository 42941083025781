import React, { useEffect, useState } from "react";
import contactLeft from "../../assets/images/AHERN_LINES_CONCEPT3.jpg";
import MexicoSouthSlider from './MexicoSouthSlider';
import {  useSelector } from "react-redux";
import { ContactUS_API, Regions } from "../../services/Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from 'react-i18next';

export const Contact = () => {
  const { languages } = useSelector((state) => state.product)
  const { t } = useTranslation("global");
  const [regions, setRegions] = useState({});
  const [selectedState, setSelectedState] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // State for submission tracking
  const [loading, setLoading] = useState(false); // State for error message tracking
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [userDetail, setUserDetail] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  });
  const [selectedUser, setSelectedUser] = useState("");

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedUser(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  

  const fetchRegions = async () => {
    try {
      const response = await Regions(); // Call your API function
      if (response?.success) {
        setRegions(response.regions); // Set the regions from the API response
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchRegions(); // Call the fetchRegions function on component mount
  }, []);

  const handleStateSelect = (state) => {
    setSelectedState(state); // Set the selected state
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
 if (userDetail.message.length > 500) {
      setIsToastVisible(true);
      toast.error(t("error.Messagelimit"), {
        onClose: () => setIsToastVisible(false),
      });
      setLoading(false);
      return false;
    }   
    if (
      !userDetail.name ||
      !selectedState ||
      !userDetail.email ||
      !userDetail.message
    ) {
      if (!isToastVisible) {
        // Check if a toast is already visible
        setIsToastVisible(true); // Set the toast visibility to true
        toast.error(t("error.fillMandatoryDetails"), {
          onClose: () => setIsToastVisible(false), // Reset the visibility when the toast is closed
        });
      }
      setLoading(false);
      return false;
    }
    setLoading(true);


    const userInfo = {
      name: userDetail.name,
      region: selectedState,
      email: userDetail.email,
      phone: userDetail.phoneNumber,
      role: selectedUser,
      message: userDetail.message,
      language: languages
    };
    try {
      await ContactUS_API(userInfo);
      setUserDetail({
        name: "",
        email: "",
        phoneNumber: "",
        message: "",
        role: "",
      });
      setSelectedState("");
      setLoading(false);
      toast.success(t('success.suceccfull submit'));
    } catch (error) {
      toast.error("An error occurred while submitting the form.");
    } finally {
      // Reset the submission state
      setIsSubmitting(false);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetail((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <>
     {loading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
      <div className="banner-main">
        <div className="banner-image-contact"></div>
      
      </div>
      <div className="custom-container sales-team-custom">
       
        <MexicoSouthSlider />
      </div>
      <div className="custom-container contact-us-main-top">
        <div className="contact-contact">
          <div className="row">
            <div className="col-lg-6 col-md-12 image">
              <div className="contact-form-left">
                <img width="100%" height="100%" src={contactLeft} alt="contact us banner"/>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="contact-form-right">
                <div className="contact-form">
                  <h3>{t("Contact Us")}</h3>
                  <h5>{t("Have a any Questions? Get in Touch!")}</h5>
                  <div className="contact-left-card">
                    <form
                      className="row contact-form-main"
                      onSubmit={handleSubmit}
                    >
                      <div className="col-md-6 mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder={`${t("Name")} *`}
                          name="name"
                          value={userDetail.name}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <div>
                          <select
                            className="form-select"
                            value={selectedState || ""}
                            onChange={(e) => handleStateSelect(e.target.value)}
                            aria-label="Select State"
                          >
                            <option value="">{`${t("Region")} *`}</option>
                            {Object.keys(regions).map((region) => (
                              <optgroup label={region} key={region}>
                                {regions[region].map((state) => (
                                  <option value={state} key={state}>
                                    {state}
                                  </option>
                                ))}
                              </optgroup>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder={`${t("Email")} *`}
                          name="email"
                          value={userDetail.email}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <input
                          type="number"
                          className="form-control"
                          id="phone"
                          placeholder={`${t("Phone")}`}
                          name="phoneNumber"
                          value={userDetail.phoneNumber}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-12 mb-3">
                        <select
                          id="userSelect"
                          className="form-select"
                          aria-label="Default select example"
                          name="Select USer"
                          value={selectedUser}
                          onChange={handleSelectChange}
                        >
                          <option value="" disabled>
                            {`${t("role")}`}
                          </option>
                          <option value="Customer">{t("Customer")}</option>
                          <option value="Supplier">{t("Supplier")}</option>
                        </select>
                      </div>

                      <div className="col-md-12 mb-3">
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          placeholder={`${t("Message")} *`}
                          rows="3"
                          name="message"
                          value={userDetail.message}
                          onChange={handleChange}
                        ></textarea>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="submit-button-main">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? t("Submitting...") : t("Submit")}
                          </button>
                        </div>
                      </div>
                      <ToastContainer />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="contact-map">
        <iframe
        title="ahern map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3363.0526948338643!2d-116.94424022434666!3d32.551438873758876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80d947abd1b39717%3A0x2e7641c190329115!2sAhern%20Agribusiness%2C%20Inc.!5e0!3m2!1sen!2sin!4v1738146138100!5m2!1sen!2sin"
          width="100%"
          height="450"
        ></iframe>
      </div>
    </>
  );
};
