import React, { useEffect, useState } from "react";
import noitceImage from "../../assets/images/notice.png";
import { ArrowIcon, PrintIcon, StarIcon } from "../../assets/svg";
import ProductSlider from "./ProductSlider";
import { ProductDetailpage } from "../../services/Api";
import { useNavigate, useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import {  useSelector } from "react-redux";

import { RequestQuote_API, Regions } from "../../services/Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { imageBaseUrl } from "../../services/configure";
import { useTranslation } from 'react-i18next';

const ProductDetail = () => {
  const [productDetailpage, setProductDetailpage] = useState([]);
  const {languages} = useSelector((state)=>state.product);
  const [show, setShow] = useState(false);
  const [regions, setRegions] = useState({});
  const [selectedState, setSelectedState] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); 
  const [loading, setLoading] = useState(false); 
  const [userDetail, setUserDetail] = useState({
    name: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
    quantity: "",
    variety: "",
  });
  const { t, i18n } = useTranslation("global");
  const [isToastVisible, setIsToastVisible] = useState(false);

   useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handlePrint = () => {
    window.print();
  };

  const { id } = useParams();
  const handleProductDetailpage = async (id) => {
    try {
      const response = await ProductDetailpage(id);
      setProductDetailpage(response?.product);
    } catch (error) {
    }
  };


const navigate=useNavigate()
  // Back button handler
  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };

  useEffect(() => {
    handleProductDetailpage(id);
  }, [id]);

  const fetchRegions = async () => {
    try {
      const response = await Regions();
      if (response?.success) {
        setRegions(response.regions);
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchRegions();
  }, []);

  const handleStateSelect = (state) => {
    setSelectedState(state);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !userDetail.name ||
      !userDetail.quantity ||
      !selectedState ||
      !userDetail.email
    ) {

      if (!isToastVisible) { 
            setIsToastVisible(true); 
            setLoading(false);
            toast.error(t('error.fillMandatoryDetails'),
              {
                onClose: () => setIsToastVisible(false), 
              }
            );
          }

      return false;
    }
    setLoading(true);

    const quoteInfo = {
      fname: userDetail.name,
      lname: userDetail.lastName,
      quantity: userDetail.quantity,
      region: selectedState,
      email: userDetail.email,
      phone: userDetail.phoneNumber,
      product_id: id,
      language:languages,
      variety: productDetailpage[0]?.name
    };

    try {
      await RequestQuote_API(quoteInfo);
      setSelectedState("");
      setLoading(false);
      toast.success(t('success.suceccfull submit'));
      setUserDetail({
        name: "",
        email: "",
        phoneNumber: "",
        lastName: "",
        quantity: "",
      });

      setTimeout(() => {
        handleClose();
      }, 2000);

    } catch (error) {
      toast.error("An error occurred while submitting the form.");
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetail((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  return (
    <>
      <div className="banner-main">
        <div className="banner-image-ahernseeds"></div>
      </div>
      {loading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
      {productDetailpage.map((item) => (
        <>
          <div className="pd-dtl-main">
            <div className="custom-container">
              <div className="pd-header">
                <div className="pd-header-left">
                  <h5>{item?.name}</h5>
                </div>
                <div className="pd-header-right">
                  <div className="pd-header-logos">
                    <div className="logo-img">
                      <img
                        width="100%"
                        height="100%"
                        src={`${imageBaseUrl + item?.supplier?.logo}`}
                        alt="supplier logo"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pd-details">
              <div className="custom-container">
                <div className="pd-main">
                  <div className="pd-left">
                    <div className="pd-details-left">
                      <img
                        width="100%"
                        src={`${imageBaseUrl + item.thumbnail}`}
                        alt="product thumbnail"
                      />
                    </div>
                  </div>
                  <div className="pd-right">
                    <div className="pd-details-right">
                      <div className="detail-head">
                        <div className="detail-head-left">
                          <h3>{(i18n.language === 'en' ? (item?.category?.name_en) : (item?.category?.name_es))}</h3>
                          
                        </div>
                        <div className="detail-head-left">
                          {item.exclusive ? (
                            <div className="ahern-exclucive">
                              <StarIcon
                                className="staricon"
                                color="#fff"
                                height="10px"
                              />
                              <p>{t("Ahern Exclusive")}</p>
                            </div>
                          ) : (
                            ""
                          )}
                          <button href="#" className="print-btn" onClick={handlePrint}>
                            <PrintIcon />
                          </button>
                        </div>
                      </div>
                      <div
                        dangerouslySetInnerHTML={{ __html: (i18n.language === 'en' ? item.description_en : item.description_es) }}
                        
                      />
                      {item.organic === "yes" ? (
                        <div className="organic-main"><h4 className="organic">{t("Organic")}</h4></div>
                      ) : item.organic === "no" ? (
                        <p></p>
                      ) : (
                        ""
                      )}
                      <div className="pd-part">
                        <div className="pd-part-left">
                          <ul>
                            <li>
                              <span>{t("Supplier :")} </span> {item?.supplier?.name}
                            </li>
                            <li>
                              <span>{t("Resistance/Tolerance :")} </span>{" "}
                              {item.resistance_tolerance}
                            </li>
                          </ul>
                        </div>
                        <div className="pd-part-right">
                          {item.exclusive ? (
                            <img
                              width="100%"
                              height="100%"
                              src={`${imageBaseUrl + item.exclusive}`}
                              alt="exclusive logo"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="pd-footer">
                        <div className="request-btn">
                          <button
                            className="btn bet-now-login"
                            onClick={handleShow}
                          >
                            {t("Request Quote")}
                          </button>
                        </div>
                        <div className="product-back-btn">
                        <ArrowIcon />
                        <button  onClick={handleBack} >  {t("Back To Product")}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="custom-container">
              <ProductSlider data={productDetailpage} />
            </div>
            <div className="custom-container">
              <div className="notice-main">
                <div className="notice-content">
                <p><span>{t("NOTICE")}</span> {t("Notice Content")}</p>
                </div>
                <div className="notice-image">
                  <img width="100%" height="100%" src={noitceImage} alt="notice"/>
                </div>
              </div>
            </div>

            <Modal
              size="lg"
              style={{ width: "100%" }}
              show={show}
              onHide={handleClose}
              className="request-modal"
            >
              <Modal.Header closeButton>
                <div className="quote-header">
                  <h3>{t("Request Quote")}</h3>
                  <p>{(i18n.language === 'en' ? (item?.category?.name_en) : (item?.category?.name_es))}</p>
                </div>
              </Modal.Header>
              <Modal.Body>
                <form className="row contact-form-main" onSubmit={handleSubmit}>
                  <div className="col-md-6 mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder={`${t("First-name")} *`}
                      name="name"
                      value={userDetail.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="lastName"
                      placeholder={`${t("Last-name")}`}
                      name="lastName"
                      value={userDetail.lastName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="Category"
                      placeholder={item?.name}
                      name="variety"
                      value={item?.name}
                      disabled
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <div>
                      <select
                        className="form-select"
                        value={selectedState || ""}
                        onChange={(e) => handleStateSelect(e.target.value)}
                        aria-label="Select State"
                      >
                        <option value="">{`${t("Region")} *`}</option>
                        {Object.keys(regions).map((region) => (
                          <optgroup label={region} key={region}>
                            {regions[region].map((state) => (
                              <option value={state} key={state}>
                                {state}
                              </option>
                            ))}
                          </optgroup>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder={`${t("Email")} *`}
                      name="email"
                      value={userDetail.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <input
                      type="number"
                      className="form-control"
                      id="phone"
                      placeholder={`${t("Phone")}`}
                      name="phoneNumber"
                      value={userDetail.phoneNumber}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="quantity"
                      placeholder={`${t("Quantity")} *`}
                      name="quantity"
                      value={userDetail.quantity}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <div className="submit-button-main">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? t("Submitting...") : t("Request Quote")}
                      </button>
                    </div>
                  </div>
                  <ToastContainer />
                </form>
              </Modal.Body>
            </Modal>
            
          </div>
        </>
      ))}
    </>
  );
};

export default ProductDetail;
