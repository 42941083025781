import React, { useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slide1 from "../../assets/images/WHAT WE OFFER_SEEDS_COLOR.jpg";
import slide2 from "../../assets/images/WHAT WE OFFER_BIO SOLUTIONS_COLOR.jpg";
import slide3 from "../../assets/images/WHAT WE OFFER_FINANCIAL_COLOR.jpg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Solutionslider = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { t } = useTranslation("global");

  return (
    <>
      <div className="custom-container solution-slider slider-container">


        <div className="row">
          <div className="col-md-4">

            <div className="image-box ">
              <Link to="/ahernseeds">
                <div className="image-overlay seeds-green">
                  <img src={slide1} alt="Background" className="w-100" />
                  <div className="head-sol">
                    <div className="heding-content">
                      <p className="text">
                        {t("seeds card")}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-md-4">
            <div className="image-box ">
              <Link to="/ahernbio">
                <div className="image-overlay seeds-green bio-yellow">
                  <img src={slide2} alt="Background" className="w-100" />
                  <div className="head-sol">
                    <div className="heding-content">
                      <p className="text">
                        {t("bio card")}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-md-4">
            <div className="image-box ">
              <div className="image-overlay seeds-green fin-blue">
                <img src={slide3} alt="Background" className="w-100" />
                <div className="head-sol">
                  <div className="heding-content">
                    <p className="text">
                      {t("fin card")} <span>{t("Coming soon")}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </>
  );
};

export default Solutionslider;
