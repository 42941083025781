import React, { useEffect } from 'react'
import bio_image from '../../assets/images/FOTO_ABEJORRO_BIOSOLUTIONS_WEB_SITE.jpg'
import ExpertTeamSlider from './ExpertTeamSlider'
import { useTranslation } from "react-i18next";

const Ahernbio = () => {

  const { t, i18n } = useTranslation("global");
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <>
      <div className="banner-main">
        <div className="banner-image-ahernbio"></div>
      </div>
      <div className='ahern-bio-main'>
        <div className="custom-container">
          <div className="bio-header width-75 mx-auto">
            <h3 className='text-center pt-5 font-weight-bold'>{t('biosolution.heading_1')}</h3>

            <div className="content-seeds">
                  <p dangerouslySetInnerHTML={{ __html: t('biosolution.section_1') }} />
                  <p dangerouslySetInnerHTML={{ __html: t('biosolution.section_2') }} />
                  <p dangerouslySetInnerHTML={{ __html: t('biosolution.section_3') }} />

              <h4 className='text-start'>{t('biosolution.heading_2')}</h4>
              <p>
                <ul className='text-start'>
                  <li>{t('biosolution.list_2_1')}</li>
                  <li>{t('biosolution.list_2_2')}</li>
                  <li>{t('biosolution.list_2_3')}</li>
                  <li>{t('biosolution.list_2_4')}</li>
                  <li>{t('biosolution.list_2_5')}</li>
                </ul>
                <div className="map-image bio-Image">
                  <img src={bio_image} alt="map" />
                </div>
              </p>
              <h4 className='text-start'>{t('biosolution.heading_3')}</h4>
              <p className='text-start'>
                <ul>
                  <li>
                  {t('biosolution.list_1')}
                  </li>
                  <li>
                  {t('biosolution.list_2')}
                  </li>
                  <li>
                  {t('biosolution.list_3')}
                  </li>
                  <li>
                  {t('biosolution.list_4')}
                  </li>
                  <li>
                  {t('biosolution.list_5')}
                  </li>
                </ul>
              </p>
              <p>
              {t('biosolution.section_5')}
              </p>
            </div>
          </div>
        </div>
      
      <div className='p-3 expert-team-section'>
        <h1>{t('biosolution.heading_4')}</h1>
        <h2> {t('biosolution.heading_5')} | {t('biosolution.heading_6')}| {t('biosolution.heading_7')}</h2>
      </div>
     
      <div className="expert-team-carousal">
        <ExpertTeamSlider />
      </div>
      </div>
    </>
  )
}

export default Ahernbio