import React, { useState, useEffect, useRef } from "react";
import { Link, Outlet, useNavigate, useLocation } from "react-router-dom";
import logoImagePurple from "../../assets/images/LOGO_AHERN_PURPLE.png";
import { SearchIcon } from "../../assets/svg";
import { useTranslation } from "react-i18next";
import Footer from "./Footer";
import { useDispatch } from "react-redux";
import { setLanguages } from "../../redux/features/productSlice";
import "bootstrap/dist/css/bootstrap.min.css";
const Layout = () => {
  const [isActive, setActive] = useState("Home");
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("global");
  const dropdownRef = useRef(null);
  const location = useLocation();
  const dispatch = useDispatch()
  const navbarRef = useRef(null); // Reference for the navbar collapse
  const [keyword, setKeyword] = useState("");
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    dispatch(setLanguages(lng))
  };
  const closeDropdown = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };
  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  const handleLinkClick = () => {
    const navbarToggler = document.querySelector(".navbar-toggler");

    if (navbarRef.current) {
      navbarRef.current.classList.remove("show");

      if (navbarToggler) {
        navbarToggler.setAttribute("aria-expanded", "false");
      }
    }
  };

  useEffect(() => {
    const path = location.pathname;
    switch(path){
      case "/":
        setActive("Home");
        break;
      case "/about-us":
        setActive("This is Ahern");
        break;
      case "/products":
        setActive("Seeds");
        break;
      case "/events" :
        setActive("Events");
        break;
      case "/ahernseeds":
        setActive("Solutions");
        break
      case "/ahernbio":
        setActive("Solutions");
        break
      case "/Ahernfin":
        setActive("Solutions");
        break
      case "/contact-us":
        setActive("Contact");
        break
      default:
        setActive("");
        break
    }
    
    if(path.startsWith('/product-detail')){
      setActive("Seeds");
    }

  }, [location]);



  useEffect(() => {
    document.addEventListener("click", closeDropdown);
    document.addEventListener("scroll", closeDropdown);
    return () => {
      document.removeEventListener("click", closeDropdown);
      document.removeEventListener("scroll", closeDropdown);
    };
  }, []);


  const handleSearch = (e) => {
    e.preventDefault();
    if (keyword !== "") {
      localStorage.setItem("searchKeyword", keyword);
      navigate(`/products`);
    }
  }

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light position-sticky top-0">
        <div className="container-fluid custom-container">
          <a className="navbar-brand" onClick={() => navigate("/")}>
            <img
              src={i18n.language === "en" ? logoImagePurple : logoImagePurple}
              alt="logo"
            />
          </a>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="collapse navbar-collapse"
            ref={navbarRef}
            id="navbarSupportedContent"
          >
            <div className="main-navigation">
              <div className="top-header">
                <div className="language-change">
                  <button
                    className={`green ${i18n.language === 'en' ? 'active' : ''}`}
                    onClick={() => { changeLanguage('en'); }}
                  >
                    EN
                  </button>
                  <button
                    className={`white ${i18n.language === 'es' ? 'active' : ''}`}
                    onClick={() => { changeLanguage('es'); }}
                  >
                    ES
                  </button>
                </div>
                <div className="nav-item contact-nav">
                  <Link className={`${isActive === 'Contact' ? 'active' : ''}`} to="/contact-us" onClick={handleLinkClick}>
                    {t("contact")}
                  </Link>
                </div>
                <div className="search-box">
                  <div className="mx-auto">
                    <div className="input-group">
                      <form className="d-flex custom-search" onSubmit={handleSearch}>
                        <input className="form-control" type="search" value={keyword} onChange={(e) => { setKeyword(e.target.value) }} placeholder={t("search")} aria-label="Search" />
                        <div className="searchicon">
                          <button type="submit" className="search-button">
                            <SearchIcon />
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link
                    className={`nav-link ${isActive === "Home" ? "activemenu" : ""
                      }`}
                    to="/"
                    onClick={() => {
                      setActive("Home");
                      handleLinkClick();

                    }}
                  >
                    {t("home")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${isActive === "This is Ahern" ? "active" : ""
                      }`}
                    to="/about-us"
                    onClick={() => {
                      setActive("This is Ahern");
                      handleLinkClick();
                    }}
                  >
                    {t("this is ahern")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${isActive === "Seeds" ? "active" : ""
                      }`}
                    to="/products"
                    onClick={() => {
                      setActive("Seeds");
                      handleLinkClick();
                    }}
                  >
                    {t("seeds")}
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className={`nav-link ${isActive === "Solutions" ? "active" : ""
                      } dropdown-toggle`}
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    onClick={() => setActive("Solutions")}
                  >
                    {t("solutions")}
                  </a>
                  <ul
                    className={`dropdown-menu seedsDropdown ${isActive === "Solutions" ? "show" : ""
                      }`}
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      <Link
                        className="dropdown-item nav-link"
                        onClick={handleLinkClick}
                        to="/ahernseeds"
                      >
                        {t("ahern Seeds")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item nav-link"
                        onClick={handleLinkClick}
                        to="/ahernBio"
                      >
                        {t("Ahern Bio Solutions")}
                      </Link>
                    </li>
                    {/* <li>
                  <Link className="dropdown-item nav-link" to="/Ahernfin">
                  {t("Ahern Financial")}
                  </Link>
                    </li> */}
                  </ul>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${isActive === "Events" ? "active" : ""
                      }`}
                    to="/events"
                    onClick={() => {
                      setActive("Events");
                      handleLinkClick();
                    }}
                  >
                    {t("Events")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </nav>
      <Outlet />
      <Footer />
    </>
  );
};

export default Layout;
