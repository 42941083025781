import "./App.css";
import { BrowserRouter } from "react-router-dom";
import AppRouters from "./routes/AppRouters";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import global_es from "./translate/es/global.json";
import global_en from "./translate/en/global.json";


function App() {
  i18next.init({
    interpolation: { escapeValue: false },
    lng: "es",
    resources: {
      es: {
        global: global_es,
      },
      en: {
        global: global_en,
      },
    },
  });

  return (
    <>
      <I18nextProvider i18n={i18next}>
        <BrowserRouter>
          <AppRouters />
        </BrowserRouter>
      </I18nextProvider>
    </>
  );
}

export default App;
