import React, { useEffect, useState } from "react";
import PartnerSlider from "./PartnerSlider";
import noitceImage from "../../assets/images/notice.png";
import { StarIcon } from "../../assets/svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import i18n from "i18next";
import { useDispatch } from "react-redux";
import { setFooterCategories } from "../../redux/features/productSlice";
import { GetCategories, GetSuppliers, FilterProductPage } from "../../services/Api";
import { imageBaseUrl } from "../../services/configure";
import { useTranslation } from "react-i18next";
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { TreeSelect } from 'primereact/treeselect';


const Seeds = () => {
  // State variables
  const [products, setProducts] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [keyword, setKeyword] = useState("");
  const productsPerPage = 10;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation("global");
  const language = i18n.language;
  const location = useLocation();

  const navigate = useNavigate();

  // Parse query parameters from the URL
  const query = new URLSearchParams(location.search);
  const initialSupplier = query.get("supplier") || null;
  const initialCategory = query.get("category") || null;
  const initialKeyword = query.get("keyword") || "";

  const [filter, setFilter] = useState({
    supplier: initialSupplier,
    category: initialCategory,
  });

  // Fetch products
  const fetchProducts = async () => {

    const pages_data = new URLSearchParams();
    pages_data.append("page", currentPage);
    pages_data.append("per_page", productsPerPage);
    if (filter.supplier) pages_data.append("supplier", filter.supplier);
    if (filter.category) pages_data.append("category", filter.category);
    if (keyword) pages_data.append("keyword", keyword);
    try {
      const response = await FilterProductPage(pages_data);
      setProducts(response.products);
      setTotalPages(response.last_page);
      window.scrollTo(0, 0);

    } catch (error) {
      console.error(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Fetch suppliers
  const fetchSuppliers = async () => {
    try {
      const response = await GetSuppliers();
      setSuppliers(response?.suppliers);
    } catch (error) {
      console.error(error);
      setError(error.message);
    } finally {
    }
  };

  // Fetch categories
  const fetchCategories = async () => {
    try {
      const response = await GetCategories();
      setCategories(response?.productCategories);
      dispatch(setFooterCategories(response?.productCategories));
    } catch (error) {
      console.error(error);
      setError(error.message);
    } finally {
    }
  };

  // Handle filter changes
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter((prevFilter) => ({ ...prevFilter, [name]: value }));
    setCurrentPage(1);
    setKeyword("");
  };

  // Handle search input
  const handleSearch = (event) => {
    if (event.target.value === '') {
      navigate('/products');
    }
    setKeyword(event.target.value);
    setCurrentPage(1); // Reset to first page
  };


  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  // Update URL with query parameters
  const updateQueryParams = () => {
    const params = new URLSearchParams();
    if (filter.supplier) params.append("supplier", filter.supplier);
    if (filter.category) params.append("category", filter.category);
    if (keyword) params.append("keyword", keyword);
    params.append("page", currentPage);
    navigate({ search: params.toString() }); // Use navigate instead of history.push
  };

  // Fetch suppliers and categories on component mount
  useEffect(() => {
    const storedKeyword = localStorage.getItem("searchKeyword");
    if (storedKeyword) {
      setKeyword(storedKeyword);
    }

    fetchSuppliers();
    fetchCategories();
    window.scrollTo(0, 0);

    return () => {
      localStorage.removeItem("searchKeyword");
    };

  }, []);

  // Set initial filter state from query parameters
  useEffect(() => {
    if (initialSupplier || initialCategory || initialKeyword) {
      setFilter({
        supplier: initialSupplier,
        category: initialCategory,
      });
      setKeyword(initialKeyword);
    }
  }, [initialSupplier, initialCategory, initialKeyword]);

  useEffect(() => {
    const handlePopstate = () => {
      if (location.search) {
        navigate('/products');
        setFilter({
          supplier: null,
          category: null,
        });
        setKeyword("");

      }
    };
    window.addEventListener('popstate', handlePopstate);
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, [location, navigate]);

  useEffect(() => {
    fetchProducts();
    updateQueryParams();
  }, [keyword, currentPage, filter]);

  const transformSuppliers = suppliers.map(supplier => ({
    label: supplier.name,
    value: supplier.id,
    key: `${supplier.id}`,
    children: supplier.associated_suppliers?.map(subItem => ({
      label: subItem.name,
      value: subItem.id,
      key: `${subItem.id}`,
    })) || []
  }));

  const transformCategories = categories.map(category => ({
    label: language === 'en' ? category.name_en : category.name_es,
    value: category.id,
    key: `${category.id}`,
    children: category.subcategories?.map(subItem => ({
      label: language === 'en' ? subItem.name_en : subItem.name_es,
      value: subItem.id,
      key: `${subItem.id}`,
    })) || []
  }));


  // Render error state
  if (error) {
    return <div className="text-center text-danger">{error}</div>;
  }

  return (
    <>
      {loading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
      <div className="banner-main">
        <div className="banner-image-seeds"></div>
        <PartnerSlider
          suppliers={suppliers}
          onSupplierSelect={(supplier) => handleFilterChange({ target: { name: 'supplier', value: supplier.id } })}
        />
      </div>
      <div className="our-product">
        <div className="custom-container">
          <div className="product-header">
            <h3>{t("We provide the best genetics for your crops")}</h3>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="filter-main">
                <div className="filter-left">
                  <div className="filter-item">
                    <TreeSelect
                      name="supplier"
                      value={filter.supplier}
                      onChange={handleFilterChange}
                      options={transformSuppliers}
                      filter
                      placeholder="Select Supplier"
                      className="md:w-20rem w-full treeselect"
                      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                      allowClear
                      getPopupContainer={trigger => trigger.parentNode}
                    />
                  </div>
                  <div className="filter-item">
                    <TreeSelect
                      id="category"
                      name="category"
                      value={filter.category}
                      onChange={handleFilterChange}
                      options={transformCategories}
                      filter
                      placeholder="Select Category"
                      className="md:w-20rem w-full treeselect"
                    />
                  </div>
                </div>

                <div className="filter-right">
                  <input
                    className="form-control"
                    id="myInput"
                    type="text"
                    placeholder={t("Search Products")}
                    value={keyword} // Controlled input
                    onChange={(e) => setKeyword(e.target.value)}
                    onClick={handleSearch}
                  />
                </div>
              </div>
            </div>
            <div className="card-dif">
              {products && products?.map((item) => (
                <div className="mb-4 p-card-top" key={item.id}>
                  <div className="product-card-main">
                    <Link
                      to={{ pathname: `/product-detail/${item.id}`, state: { filter } }}
                    >
                      <div className="product-card">
                        <div className="row">
                          <div className="col-md-4 product-image">
                            <img
                              width="100%"
                              height="100%"
                              src={`${imageBaseUrl + item?.thumbnail}`}
                              alt={item.name}
                            />
                          </div>
                          <div className="col-md-8 product-content">
                            <div className="product-cont-top">
                              <div className="syngenta">
                                <h5>{item?.supplier?.name}</h5>
                                {item.exclusive && (
                                  <div className="varified">
                                    <StarIcon color="#FFFFFF" />
                                    <h4>{t("Featured Variety")}</h4>
                                  </div>
                                )}
                              </div>
                              <h3>{item?.name}</h3>
                              <div className="desc-pro" dangerouslySetInnerHTML={{ __html: (i18n.language === 'en' ? item.description_en : item.description_es) }} />
                              <a href="">{t("Read More")}</a>
                              <p className="category">
                                {item.category.parent && (
                                  <>
                                    {i18n.language === 'en' ? item.category.parent.name_en : item.category.parent.name_es} &gt;
                                  </>
                                )}
                                &nbsp;
                                {i18n.language === 'en' ? item.category.name_en : item.category.name_es}
                              </p>

                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
            {!products.length && <h3>No Product Found</h3>}
          </div>
          {totalPages > 1 && (
            <div className="pagination-controls">
              <button
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                style={{
                  backgroundColor: currentPage === 1 ? "gray" : "green",
                  color: currentPage === 1 ? "white" : "white",
                  border: "none",
                }}
              >
                {t("Previous")}
              </button>

              <span>
                {t("Page")} {currentPage} {t("of")} {totalPages}
              </span>
              <button
                onClick={handleNextPage}
                disabled={currentPage >= totalPages}
                style={{
                  backgroundColor: currentPage >= totalPages ? "gray" : "green",
                  color: currentPage >= totalPages ? "#fff" : "white",
                  border: "none",
                }}
              >
                {t("Next")}
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="custom-container">
        <div className="notice-main">
          <div className="notice-content">
            <p>
              <span>{t("NOTICE")}</span> {t("Notice Content")}
            </p>
          </div>
          <div className="notice-image">
            <img width="100%" height="100%" src={noitceImage} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Seeds;
